<script setup lang="ts">
  import { computed } from 'vue'
  import { RouteLocationRaw, RouterLinkProps } from 'vue-router'

  const props = withDefaults(
    defineProps<
      {
        secondary?: boolean
        primary?: boolean
        tertiary?: boolean
        external?: boolean
        inline?: boolean
      } & RouterLinkProps
    >(),
    {
      primary: false,
      secondary: false,
      tertiary: false,
      external: false,
      inline: false,
    }
  )

  const classes = computed(() => [
    'hover:opacity-control base-control',
    {
      'gc-secondary': props.secondary,
      'gc-primary': props.primary,
      'gc-tertiary': props.tertiary,
      'gc-inline': props.inline,
    },
  ])

  const toAsString = (to: RouteLocationRaw) => {
    if (typeof to === 'string') {
      return to
    }

    throw new Error('For external links "to" has to be a string')
  }
</script>

<template>
  <a
    v-if="external"
    :class="classes"
    :href="toAsString(to)"
    rel="noopener"
    target="_blank"
  >
    <slot />
  </a>
  <router-link v-else :class="classes" v-bind="$props">
    <slot />
  </router-link>
</template>

<style scoped>
  .gc-secondary {
    @apply text-cyan underline;
  }

  .gc-primary {
    @apply text-cyan w-52 min-w-fit rounded-full border-current text-center font-extrabold uppercase;
  }

  .gc-tertiary {
    @apply border-gray-var-600 text-gray-var-300 flex items-center gap-2 space-x-2;
  }

  .gc-inline {
    @apply inline px-0;
  }
</style>
